import React, {Component} from "react";
import './Chinese-painting.css';

class ChinesePainting extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Китайская живопись</h2>
                <p>Китайская живопись как вид изобразительного искусства и одна из немногих живых восточных традиций живописи, 
                    зародилась в древнем Китае и непрерывно эволюционирует со времен неолита.</p>
                <p>Среди жанров китайской живописи выделяют следующие:</p>
                <p>Пейзаж «гора — вода». Тип китайской живописи, на которой основной частью изображены природные пейзажи гор и рек.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/pait_1.png" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
                <div className="container"><hr/></div>

                <p>Живопись цветов и птиц - это разновидность китайской живописи, в которой в качестве объектов используются 
                    цветы, птицы и насекомые. На китайских картинах картины с изображением цветов, птиц, рыб и насекомых 
                    называются цветочными и птичьими картинами. В рисовании цветов и птиц есть три метода рисования: «гунби», 
                    «рисование от руки» и «работа на полставки с письмом.</p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/pait_4.png" className="w-100 mt-2 mb-2 " alt=""/>
                    </div>
                </div>
                <div className="container"><hr/></div>

                <p>Живопись бамбука</p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/pait_2.png" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
                <div className="container"><hr/></div>

                <p>Анималистическая живопись фигур - это разновидность живописи, то есть общий термин для рисования с человеческими фигурами в качестве 
                основного тела. Рисование фигур направлено на то, чтобы изобразить личность персонажа живо в форме и духе. Его яркий 
                метод часто воплощает выражение характера персонажа в визуализации окружающей среды, атмосферы, тела и динамики. 
                Картины китайских фигур грубо делятся на даосские картины, портреты, жанровые картины, картины красоты, картины 
                исторических историй и т. д.</p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/pait_3.jpg" className="w-100 mt-2 mb-3" alt=""/>
                    </div>
                </div>

            </div>

        );
    }
}

export default ChinesePainting;