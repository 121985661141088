import React, { Component } from "react";
import './form.css';


class forms extends Component {
	render() {
		return (
			<div className="container">
				<h1 className="text-center">Записаться на курсы китайского языка</h1>
				<form className="form" action="email_telegram1.php" method="POST">
					<div className="form-group">
						<div className="row list-form">
							<div className="col-md-4 col-12">
								<label className="labelForm-group" htmlFor="">Имя</label>
								<input type="text" className="form-control" id="" name="user_name" placeholder="Иван" required />
							</div>
							<div className="col-md-4 col-12">
								<label className="labelForm-group" htmlFor="">Телефон</label>
								<input type="text" className="form-control" id="" name="user_phone" placeholder="+7 999 999 99 99" required />
							</div>
							<div className="col-md-4 col-12">
								<label className="labelForm-group" htmlFor="">Введите email</label>
								<input type="text" className="form-control" id="" name="user_email" placeholder="ivan@mail.ru" required
								/>
							</div>
						</div>
					</div>
					<h3 className="text-center">Возрастной уровень</h3>
					<div className="form-group">
						<label htmlFor="mc1" className="form-span">
							<input type="radio" name="user_course" value="Курсы обучения для школьников"
								id="mc1" required />
							<span className="form-text">Курсы обучения для школьников</span></label>
						<label htmlFor="mc2" className="form-span">
							<input type="radio" name="user_course" value="Курсы обучения для взрослых"
								id="mc2" required />
							<span className="form-text">Курсы обучения для взрослых</span></label>
					</div>

					<h3 className="text-center">Уровень подготовки</h3>
					<div className="form-group">
						<div className="row block-form-group">
							<div className="col-md-6 col-12">
								<label className="form-span" htmlFor="lv1"><input type="radio" name="user_level"
									value="Основной начинающий" id="lv1" required />
									<span className="form-text">Основной начинающий</span>
								</label>
							</div>
							<div className="col-md-6 col-12">
								<label htmlFor="lv2" className="form-span">
									<input type="radio" name="user_level" value="Основной средний"
										id="lv2" required />
									<span className="form-text">Основной средний</span></label>
							</div>
							<div className="col-md-6 col-12">
								<label htmlFor="lv3" className="form-span">
									<input type="radio" name="user_level" value="Основной продвинутый" id="lv3" required />
									<span className="form-text">Основной продвинутый</span></label>
							</div>
							<div className="col-md-6 col-12">
								<label htmlFor="lv4" className="form-span">
									<input type="radio" name="user_level" value="Разговорый клуб" id="lv4" required />
									<span className="form-text">Разговорый клуб</span></label>

							</div>
							<div className="col-md-6 col-12">
								<label htmlFor="lv5" className="form-span">
									<input type="radio" name="user_level" value="Индивидуальные уроки" id="lv5" required />
									<span className="form-text">Индивидуальные уроки</span></label>
							</div>
							<div className="col-md-6 col-12">
								<label htmlFor="lv6" className="form-span">
									<input type="radio" name="user_level" value="Подготовка к экзаменам HSK"
										id="lv6" required />
									<span className="form-text">Подготовка к экзаменам HSK</span></label>
							</div>
							<div className="col-md-6 col-12">
								<label htmlFor="lv7" className="form-span">
									<input type="radio" name="user_level" value="Поготовка к экскурсии по Китаю"
										id="lv7" required />
									<span className="form-text">Подготовка к экскурсии по Китаю</span></label>
							</div>
							<div className="col-md-6 col-12">
								<label htmlFor="lv8" className="form-span">
									<input type="radio" name="user_level" value="Поготовка к учебе в Китае" id="lv8" required />
									<span className="form-text">Подготовка к учебе в Китае</span></label>
							</div>
							<div className="col-md-6 col-12">
								<label htmlFor="lv9" className="form-span">
									<input type="radio" name="user_level" value="Поготовка к учебе в Китае" id="lv9" required />
									<span className="form-text">Подготовка к учебе в Китае</span></label>
							</div>
							<div className="col-md-6 col-12">
								<label htmlFor="lv10" className="form-span">
									<input type="radio" name="user_level" value="Поготовка к бизнес встерче в Китае"
										id="lv10" required />
									<span className="form-text">Поготовка к бизнес встерче в Китае</span></label>
							</div>
							<div className="col-12">
								<label htmlFor="lv11" className="form-span">
									<input type="radio" name="user_level" value="Обсудить при встрече" id="lv11" required />
									<span className="form-text">Обсудить при встрече</span></label>
							</div>
							<h3 className="m-auto p-3">Дополнительная информация</h3>
							<div className="textarea-group col-12">
								<textarea rows="5" name="text" placeholder="Заполните это поле если хотите сообщить дополнительные сведения">
								</textarea>
							</div>
						</div>
						<div className="container"><p>Нажимая на кнопку, вы даете согласие на <a href="PD.pdf">обработку
						своих персональных
                            данных</a></p></div>


						<button type="submit" className="btn d-flex m-auto btn-primary">Отправить форму</button>
					</div>

				</form>
			</div>
		)
			;
	}
}

export default forms;