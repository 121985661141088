import React, {Component} from "react";
import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";


class Modals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false

        }
    }

    handleClick() {
        this.setState({show: !this.state.show})
    }

    render() {
        return (
           <>

               <Modal show={this.state.show}>
                   <Modal.Header>
                       <h6 className="ml-3">Оставить заявку или узнать подробности Вы можете по телефону: +7 (989)
                           629-31-56 или заполнив форму ниже:</h6>
                   </Modal.Header>
                   <Modal.Body>
                       <form action="email_telegram.php" method="POST">
                           <div className="form-group">
                               <label className="d-flex justify-content-center" htmlFor="">Введите ваше имя</label>
                               <input type="text" className="form-control" id="" name="user_name"
                                      placeholder="Например, Иван" required/>
                           </div>

                           <div className="form-group">
                               <label className="d-flex justify-content-center" htmlFor="">Введите номер
                                   телефона</label>
                               <input type="text" className="form-control" id="" name="user_phone"
                                      placeholder="+7 (999) 999 99 99" required/>
                           </div>

                           <div className="form-group">
                               <label className="d-flex justify-content-center" htmlFor="">Введите email</label>
                               <input type="text" className="form-control" id="" name="user_email"
                                      placeholder="name@mail.ru" required/>
                           </div>
                           <label className="d-flex justify-content-center" htmlFor="">Дополнительная
                               информация</label>
                           <div className="w-100"><textarea rows="3" cols="63" class="form-control" name="text">
								</textarea></div>
                           <button type="submit" className="btn btn-primary mt-2 w-100">Отправить</button>
                       </form>
                   </Modal.Body>
                   <Modal.Footer>
                       <Button className="btn-danger" onClick={() => {
                           this.handleClick()
                       }}>Закрыть</Button>
                   </Modal.Footer>
               </Modal>

               <Button variant="outline-light" className="button-carousel" onClick={() => {
                   this.handleClick()
               }}>Связаться с нами</Button>

           </>
        );
    }
}

export default Modals;