import React, { Component } from "react";
import { Map, Placemark, YMaps } from "react-yandex-maps";
import './contact.css';


const mapData = {
	center: [47.219754, 39.710755],
	zoom: 15,
};

const coordinates = [
	[47.219754, 39.710755],

];

class contact extends Component {
	render() {
		return (
			<div className="container">

				<h2 className="mt-2 mb-5 text-center">Наши контакты</h2>

				<div className="container mt-3 cart-contact">
					<div className="row">
						<div className="col-4 col-md-2 cart-contact-text">
							<img src="img/contact/contact-1.jpg" alt="Чжан Цзинвэй" />
						</div>
						<div className="col-8 col-md-10 contact-text">
							<h3>Чжан Цзинвэй</h3>
							<div>Должность: председатель Центра Евроазиатского гуманитарного сотрудничества
                            </div>
							<div>Телефон: <a href="tel:+79896293156">+7 (989) 629-31-56</a></div>
							<div>E-mail: <a href="mailto:zhang.jingwei@ceags.ru">zhang.jingwei@ceags.ru</a></div>
						</div>
					</div>
				</div>

				<div className="container mt-3 cart-contact">
					<div className="row">
						<div className="col-4 col-md-2 cart-contact-text">
							<img src="img/contact/contact-2.jpg" alt="Ли Дяньгуан"/>
						</div>
						<div className="col-8 col-md-10 contact-text">
							<h3>Ли Дяньгуан</h3>
							<div>Должность: председатель представительства в Китае Центра Евроазиатского гуманитарного сотрудничества.
                            </div>
							<div>Телефон: <a href="tel:+8618800000315">+861 (880) 000 03 15</a></div>
							<div>E-mail: <a href="mailto:550503188@qq.com">550503188@qq.com</a></div>
						</div>
					</div>
				</div>

				<div className="container mt-3 cart-contact">
					<div className="row">
						<div className="col-4 col-md-2 cart-contact-text">
							<img src="img/contact/contact-3.jpg" alt="Ивлиев Кирилл Сергеевич"/>
						</div>
						<div className="col-8 col-md-10 contact-text">
							<h3>Ивлиев Кирилл Сергеевич</h3>
							<div className="contact">
								<div>Должность: начальник отдела информационного обеспечения Центра Евроазиатского гуманитарного сотрудничества.
                                </div>
								<div>Телефон: <a href="tel:+79289093616">+7 (928) 909-36-16</a></div>
								<div>E-mail: <a href="mailto:ivliev.kirill@ceags.ru">ivliev.kirill@ceags.ru</a></div>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-3 cart-contact">
					<div className="row">
						<div className="col-4 col-md-2 cart-contact-text">
							<img src="img/contact/contact-4.jpg" alt="Чэнь Чжипин"/>
						</div>
						<div className="col-8 col-md-10 contact-text">
							<h3>Чэнь Чжипин</h3>
							<div className="contact">
								<div>Должность: начальник отдела продвижения Российской культуры и русского языка в Китае Центра Евроазиатского гуманитарного сотрудничества.
                                </div>
								<div>E-mail: <a href="mailto:990948286@qq.com">990948286@qq.com</a></div>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-3 cart-contact">
					<div className="row">
						<div className="col-4 col-md-2 cart-contact-text">
							<img src="img/contact/contact-5.jpg" alt="Каракуц Андрей Николаевич"/>
						</div>
						<div className="col-8 col-md-10 contact-text">
							<h3>Каракуц Андрей Николаевич</h3>
							<div className="contact">
								<div>Должность: начальник отдела международного сотрудничества Центра Евроазиатского гуманитарного сотрудничества.
                                </div>
								<div>Телефон: <a href="tel:+79289666627">+7 (928) 966-66-27</a></div>
								<div>E-mail: <a href="mailto:karakuc.andrey@ceags.ru">karakuc.andrey@ceags.ru</a></div>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-3 cart-contact">
					<div className="row">
						<div className="col-4 col-md-2 cart-contact-text">
							<img src="img/contact/contact-6.jpg" alt="Пэй Шосин"/>
						</div>
						<div className="col-8 col-md-10 contact-text">
							<h3>Пэй Шосин</h3>
							<div>Должность: начальник отдела продвижения китайской культуры и китайского языка Центра Евроазиатского гуманитарного сотрудничества.
                            </div>
							<div>Телефон: <a href="tel:+79897145244">+7 (989) 714-52-44</a></div>
							<div>E-mail: <a href="mailto:pei.shuoxing@ceags.ru">pei.shuoxing@ceags.ru</a></div>
						</div>
					</div>
				</div>

				<div className="container mt-3 cart-contact">
					<div className="row">
						<div className="col-4 col-md-2 cart-contact-text">
							<img src="img/contact/contact-7.jpg" alt="Фан Кайфан"/>
						</div>
						<div className="col-8 col-md-10 contact-text">
							<h3>Фан Кайфан</h3>
							<div>Должность: начальник организационного отдела Центра Евроазиатского гуманитарного сотрудничества.
                            </div>
							<div>Телефон: <a href="tel:+79185528173">+7 (918) 552-81-73</a></div>
							<div>E-mail: <a href="mailto:flianxuan@qq.com">flianxuan@qq.com</a></div>
						</div>
					</div>
				</div>

				<div className="container mt-3">
					<div className="row">
						<div className="col-4 col-md-2 cart-contact-text">
							<img src="img/contact/contact-8.jpg" alt="Черникова Маргарита Юрьевна"/>
						</div>
						<div className="col-8 col-md-10 contact-text">
							<h3>Черникова Маргарита Юрьевна</h3>
							<div>Должность: заместитель начальника отдела международного сотрудничества Центра Евроазиатского гуманитарного сотрудничества.
                            </div>
							<div>Телефон:<a href="tel:+79198981291">+7 (919) 898-12-91</a></div>
							<div>E-mail: <a href="mailto:chernikova.margarita@ceags.ru">chernikova.margarita@ceags.ru</a></div>
						</div>
					</div>
				</div>

				<div className="container mt-5 mb-5">
					<p>Наш офис расположен в историческом центре г. Ростова-на-Дону, недалеко от парка Горького, на
					территории живописной пешеходной зоны Соборного переулка. Именно здесь вы сможете погрузиться в
					атмосферу Китая, открыть для себя новые знания благодаря нашим курсам, найти единомышленников и
					сказать 你好 ([nǐhǎo] - здравствуйте) новым мечтам и горизонтам.
                    </p>
					<p>Адрес ЦЕАГС “ОДИН ПУТЬ” в г. Ростове-на-Дону: <br></br>пер. Соборный д. 21, офис 4б</p>
					<p>Контактные данные:<br></br>
					<a href="mailto:info@ceags.ru">info@ceags.ru</a><br></br>
					<a href="tel:+79896293156">+7 (989) 629-31-56</a></p>
					<p>Время работы:<br></br>
					пн-вс 9:00-21:00</p>
					<h3 className="text-center">Приходите, всегда рады Вас видеть!</h3>
				</div>

				<div className="container">
					<div className="index-card mt-3">
						<YMaps>
							<Map width='100%'
								height='400px' defaultState={mapData}>
								{coordinates.map(coordinate => <Placemark geometry={coordinate} />)}
							</Map>
						</YMaps>
					</div>
				</div>

				<div className="container">
					<div className="row">
						<div className="col-12 text-center mt-3 mb-5 requisites">
							<h5>Реквизиты:</h5>
							<h6>ООО "ЦЕНТР ЕВРОАЗИАТСКОГО ГУМАНИТАРНОГО
                                СОТРУДНИЧЕСТВА "ОДИН ПУТЬ"</h6>
							<h6>ОГРН 1186196052833</h6>
							<h6>ОКПО 34913172</h6>
							<h6>ИНН 6165217101</h6>
							<h6>КПП 616501001</h6>
							<h6>Генеральный директор: Гладышев-Лядов Владимир Васильевич</h6>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default contact;