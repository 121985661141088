import React, {Component} from "react";

class universityCooperation extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Межвузовское сотрудничество</h2>
                <p>Центр Евроазиатского Гуманитарного Сотрудничества "Один Путь" готов поспособствовать 
                    заключению договоров о сотрудничестве между вузами РФ и КНР в области международного 
                    академического обмена. Приток китайских студентов, готовых обучаться в российском 
                    вузе на коммерческой основе, способствует наполнению бюджета вуза и привлечению 
                    финансирование в российское образование в целом.</p>
            </div>

        );
    }
}

export default universityCooperation;