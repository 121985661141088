import React, {Component} from "react";
import './Group-Chinese-courses.css';
import {Button} from "react-bootstrap";


class GroupChineseCourses extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Групповые курсы китайского языка</h2>
                <p>Основной курс ориентирован на взрослую аудиторию (старше 16 лет) как для начинающих, так и для
                    продолжающих изучение китайского языка.</p>
                <ul>
                    <li>На каждом занятии развиваются навыки владения иностранным языком: разговорная речь, восприятие и
                        понимание речи на слух, письмо, чтение;
                    </li>
                    <li>Занятия проводят носители языка, владеющие русским языком;</li>
                    <li>Уровни подготовки: начальный, базовый, продвинутый, профессиональный;</li>
                    <li>Программа рассчитана на 2 год обучения;</li>
                    <li>Количество учащихся в группе 2-6 человек;</li>
                    <li>Занятия проводятся и в будние, и в выходные дни;</li>
                    <li>Можно прийти и бесплатно посмотреть как проходит занятие уже сформировавшейся группы.</li>
                    <li>Один цикл (8 занятий) стоит 5000 рублей! одно занятие идёт час (60 минут), два занятия в
                        неделю.
                    </li>
                </ul>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="/img/3/1.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                <div className="w-100 text-center mb-5 mt-3">
                    <Button href="/forms" variant="primary">Записаться на курсы</Button>
                </div>
            </div>

        );
    }
}

export default GroupChineseCourses;