import React, {Component} from "react";
import './Chinese-holidays.css';


class ChineseHolidays extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Китайские традиционные праздники</h2>


                <p>Выделяют следующие основные китайские праздники:</p>


                    <li>Традиционный Новый год - 1 января;</li>
                    <p></p>
                    <p>На праздник Традиционного Нового года принято собираться вместе с друзьями 
                        на трапезу самого популярного блюда (ХОГО).</p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="/img/culture/holl_1.png" className="w-100" alt=""/>
                    </div>
                    </div>
                    <p></p>
                    <div className="container"><hr/></div>

                    <li>Китайский праздник весны (согласно лунному календарю каждый год даты разные, 
                        в промежутке с 21 января по 21 февраля)</li>
                    <p></p>
                    <p>В это время устоялось готовить пельмени, наклеивать парные полосы красной бумаги с новогодними 
                        пожеланиями，поздравлять людей с новым годом</p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="/img/culture/holl_2.png" className="w-100" alt=""/>
                    </div>
                    </div>
                    <p></p>
                    <div className="container"><hr/></div>

                    <li>Цинмин (традиционный день поминовения усопших, 
                        на 104-й день после зимнего солнцестояния или 15-й день после весеннего равноденствия)</li>
                    <p></p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="/img/culture/holl_4.png" className="w-100" alt=""/>
                    </div>
                    </div>
                    <p></p>
                    <div className="container"><hr/></div>

                    <li>День солидарности трудящихся - 1 Мая</li>
                    <p></p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="/img/culture/holl_5.png" className="w-100" alt=""/>
                    </div>
                    </div>
                    <p></p>
                    <div className="container"><hr/></div>

                    <li>Праздник начала лета,〝двойной пятёрки〞，праздник драконьих лодок 
                        (5-го числа 5-го месяца по китайскому лунному календарю ，обычно приходится на июнь)</li>
                        <p>Принято готовить цзунцзы (блюдо из клейкого риса с разнообразными начинками в бамбуковых или др. листьях) и гонги-лодки в форме дракона</p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="/img/culture/holl_6.png" className="w-100" alt=""/>
                    </div>
                    </div>
                    <p></p>
                    <div className="container"><hr/></div>

                    <li>Праздник середины осени – 15 день 8-го лунного месяца</li>
                        <p>Принято дарить друг другу юэбин(лунный пряник)</p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="/img/culture/holl_7.png" className="w-100" alt=""/>
                    </div>
                    </div>
                    <p></p>
                    <div className="container"><hr/></div>

                    <li>День создания КНР – 1 октября</li>
                        <p></p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="/img/culture/holl_8.png" className="w-100" alt=""/>
                    </div>
                    </div>
                    <p></p>

                    

                
                
            </div>

        );
    }
}

export default ChineseHolidays;