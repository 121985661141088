import React, {Component} from "react";
import {Button, Card, Accordion} from "react-bootstrap";
import './News.css';


class News extends Component {

    
    render() {
        return (
           <>

              
			  <h5 className="text-center mb-0">Новости:</h5>
			  {/*Начало новости */}
				<div className="container"><hr/></div>
				<div className="container">
					<h5 className="mt-3 mb-5 text-center">При участии ЦЕАГС "Один Путь" в Китае проведен конкурс "Титаны науки и культуры говорят по-русски"</h5>
					<div className="d-flex justify-content-end">24.04.2021</div>
					<div className="row justify-content-center">
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-6.jpeg" alt="" />
						</div>
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-6.1.jpeg" alt="" />
						</div>
					</div>

					<div className="w-100 text-center">
					<Accordion defaultActiveKey="0">
							<Card border="light">
								<Card.Header>
									<div className="row justify-content-center">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
									Узнать подробнее
									</Accordion.Toggle>
									</div>
								</Card.Header>
									<Accordion.Collapse eventKey="1">
									<Card.Body>
<p>			ЦЕАГС "Один Путь" совместно с Южным федеральным университетом и Хэнаньским университетом легкой промышленности провели в Китае конкурс "Титаны науки и культуры говорят по-русски".</p>
<p>			В мероприятии с китайской стороны участвовало более 1500 студентов Хэнаньского университета легкой промышленности, 150 победителей были награждены памятными грамотами. Конкурс широко освещался в китайских СМИ и в социальных сетях, что позволило привлечь внимание граждан КНР к теме изучения российской культуры. Также по центральному телеканалу провинции Хэнань, в которой проживает более 100 миллионов человек, в вечерний прайм-тайм был показан специальный репортаж о конкурсе. С российской стороны, при поддержке ЦЕАГС "Один Путь", партнером конкурса выступил Институт филологии, журналистики и межкультурной коммуникации.</p>
<p>			Проект «Титаны науки и культуры говорят по-русски», посвященный выдающимся ученым, писателям, деятелям искусства России и русскому языку, поспособствовал укреплению связей в между Китайской Народной Республикой и Российской Федерацией.</p>
			</Card.Body>
						</Accordion.Collapse>
							</Card>
						</Accordion>
					
					</div>
					</div>
{/*Конец новости */}
			  {/*Начало новости */}
				<div className="container"><hr/></div>
				<div className="container">
					<h5 className="mt-3 mb-5 text-center">Подписание трехстороннего договора о 
					сотрудничестве между Шаньдунским университетом Сехэ, Южным федеральным университетом и ЦЕАГС "Один Путь"</h5>
					<div className="d-flex justify-content-end">14.04.2021</div>
					<div className="row justify-content-center">
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-5.jpeg" alt="" />
						</div>
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-5.1.jpeg" alt="" />
						</div>
					</div>
					<div className="w-100 text-center">
					<Accordion defaultActiveKey="0">
							<Card border="light">
								<Card.Header>
									<div className="row justify-content-center">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
									Узнать подробнее
									</Accordion.Toggle>
									</div>
								</Card.Header>
									<Accordion.Collapse eventKey="1">
									<Card.Body>
<p>			Сегодня состоялось подписание трехстороннего договора о сотрудничестве между Шаньдунским университетом Сехэ, Южным федеральным университетом и ЦЕАГС "Один Путь".</p>
<p>			Договор предусматривает усиление сотрудничества в сфере образования между его участниками и организацию совместных программ магистратуры, обучение в которой будет проходить на английском языке. Данный договор позволит повысить престиж российского образования за рубежом и привлечь в него дополнительные инвестиции.</p>
<p>			Университет Сехэ был основан в 1993 году. Он состоит из двух кампусов - ГоДянь и ЯоЦян, занимает площадь 1,087 миллиона квадратных метров, общая площадь всех зданий университета равняется 453 800 квадратных метров.</p>
<p>			В состав Университета Сехэ входит 8 институтов, 1 национальный демонстрационный экспериментальный учебный центр, 1 национальный экспериментальный учебный центр, в котором для обучения студентов активно используются технологии виртуальной реальности.</p>
<p>			В университете проходит обучение по 37 специальностям для бакалавриата и 32 специальностям для студентов колледжа при университете. Образовательная база университета позволила сформировать систему обучения, обеспечивающую скоординированное развитие нескольких дисциплин, таких как медицина, инженерное дело, литература, менеджмент, педагогика и искусство. В настоящее время в университете обучается 26 000 студентов, преподавательский состав включает в себя 1700 высококвалифицированных специалистов.</p>
			</Card.Body>
						</Accordion.Collapse>
							</Card>
						</Accordion>
					
					</div>
					</div>
{/*Конец новости */}
			  {/*Начало новости */}
				<div className="container"><hr/></div>
				<div className="container">
					<h5 className="mt-3 mb-5 text-center">Китайский новый год</h5>
					<div className="d-flex justify-content-end">12.02.2021</div>
					<div className="row justify-content-center">
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-5.jpg" alt="" />
						</div>
					</div>
					</div>
				<div className="container"><hr/></div>
{/*Конец новости */}
              {/*Начало новости */}
				<div className="container">
					<h5 className="mt-3 mb-5 text-center">Встреча с ректором ЮФУ</h5>
					<div className="d-flex justify-content-end">25.12.2020</div>
					<div className="row justify-content-center">
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-4.2.jpeg" alt="" />
						</div>
					</div>
					<div className="w-100 text-center">
					<Accordion defaultActiveKey="0">
							<Card border="light">
								<Card.Header>
									<div className="row justify-content-center">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
									Узнать подробнее
									</Accordion.Toggle>
									</div>
								</Card.Header>
									<Accordion.Collapse eventKey="1">
									<Card.Body>
<p>			25-го декабря представители Центра Евроазиатского гуманитарного сотрудничества «Один путь» приняли участие во встрече с ректором ЮФУ - Шевченко Инной Константиновной. В ходе встречи стороны вручили друг другу новогодние подарки, поздравив друг друга с наступающим Новым годом.</p>
<p>			На встрече Чжан Цзинвэй обсудил с Инной Константиновной вопрос открытия совместного российско-китайского университета Хэнаньской образовательно-инвестиционной компании «Динсинь» (провинция Хэнань, КНР), а также расширение сотрудничества по следующим направлениям:</p>
<p>			1.	Южный федеральный университет и ЦЕАГС «Один путь» планируют совместно развивать программу бакалавриата.</p>
<p>			2.	Южный федеральный университет и ЦЕАГС «Один путь» планируют совместно развивать магистерскую программу.</p>
<p>			3.	Южный федеральный университет и ЦЕАГС «Один путь» планируют совместно развивать программу обучения на английском языке в аспирантуре.</p>
<p>			Инна Константиовна подчеркнула, что ЮФУ заинтересован в развитии совместных программ по обучению и межгосударственному образовательному обмену, так как они создают хорошие возможности по усилению сотрудничества между ЮФУ в высшими учебными заведениями КНР.</p>
<p>			В рамках развития вышеуказанных программ, после нормализации эпидемиологической обстановки и снятия ограничений, планируется совместный визит делегации ЮФУ и сотрудников ЦЕАГС «Один путь» в КНР.</p>
<p>			Участники встречи высоко оценили ее итоги и в дальнейшем планируют развивать сотрудничество по данному направлению.</p>
			</Card.Body>
						</Accordion.Collapse>
							</Card>
						</Accordion>
					
					</div>
				</div>
				<div className="container"><hr/></div>
{/*Конец новости */}
{/*Начало новости */}
				<div className="container">
					<h5 className="mt-3 mb-5 text-center">Встреча с директором Института управления ЮФУ</h5>
					<div className="d-flex justify-content-end">21.12.2020</div>
					<div className="row justify-content-center">
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-3.jfif" alt="" />
						</div>
					</div>
					<div className="w-100 text-center">
						<Accordion defaultActiveKey="0">
							<Card border="light">
								<Card.Header>
									<div className="row justify-content-center">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
									Узнать подробнее
									</Accordion.Toggle>
									</div>
								</Card.Header>
									<Accordion.Collapse eventKey="1">
									<Card.Body>
<p>		21-го декабря директор факультета управления (ЮФУ) профессор, д.э.н. Шевченко Дмитрий Александрович встретился с представителями Центра Евроазиатского гуманитарного сотрудничества «Один путь». Чжан Цзинвэй от имени ЦЕАГС «Один путь» подарил директору китайский новогодний подарок и поздравил его с натупающим новым годом.</p>
<p>		На встрече Чжан Цзинвэй обсудил с Дмитрием Александровичем успеваемость китайских студентов, а так же расширение сотрудничества по следующим направлениям:</p>
<p>		1.	Южный федеральный университет и ЦЕАГС «Один путь» планируют совместно развивать магистерскую программу по направлению "менеджмент" с обучением на английском языке.</p>
<p>		2.	Южный федеральный университет и ЦЕАГС «Один путь» планируют совместно развивать программу обучения на английском языке в аспирантуре.</p>
<p>		Следует отметить, что программы обучения на английском языке существенно расширяют возможности для той части студентов из КНР, которые пока недостаточно хорошо овладели русским языком.</p>
<p>		Дмитрий Александрович подчеркнул, что в 2022 году факультет управления ЮФУ планирует расширять связи с высшими учебными заведениями КНР, в рамках которого, после нормализации эпидемиологической обстановки, совместно с сотрудниками Центра Евроазиатского гуманитарного сотрудничества планируется совместная командировка в Китай.</p>
<p>		Участники встречи высоко оценили ее итоги.</p>
										</Card.Body>
									</Accordion.Collapse>
							</Card>
						</Accordion>
					</div>
					</div>
					<div className="container"><hr/></div>
{/*Конец новости */}
{/*Начало новости */}
				<div className="container">
					<h5 className="mt-3 mb-5 text-center">Встреча рабочей группы ЦЕАГС с директором ИФЖК (ЮФУ)</h5>
					<div className="d-flex justify-content-end">8.10.2020</div>
					<div className="row justify-content-center">
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-2.jpeg" alt="" />
						</div>
					</div>
					<div className="w-100 text-center">
						<Accordion defaultActiveKey="0">
							<Card border="light">
								<Card.Header>
									<div className="row justify-content-center">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
									Узнать подробнее
									</Accordion.Toggle>
									</div>
								</Card.Header>
									<Accordion.Collapse eventKey="1">
										<Card.Body>
<p>		Директор ИФЖК (ЮФУ) профессор, д.э.н. Ломакина Гульнара Рашидовна провела встречу с представителями Центра Евроазиатского гуманитарного сотрудничества «Один путь». Гульнара Рашидовна, Чжан Цзинвэй и Пэй Шосин обсудили вопросы развития академического сотрудничества с университетами Китая. В ходе встречи также были затронуты темы совместных российско-китайских мероприятий в сфере культуры и изучения китайского языка. В качестве приоритетных участники выделили следующие направления сотрудничества: содействие изучению китайского языка в России, развитие программ мобильности обучающихся всех уровней обучения, в том числе на основе разработки совместных образовательных программ, организацию гостевых лекций преподавателей из КНР.</p>
<p>		Гульнара Рашидовна подчеркнула, что ИФЖК (ЮФУ) заинтересован в развитии академического обмена и сотрудничества с китайскими университетами.</p>
<p>		Южный федеральный университет создан на базе «Ростовского государственного университета», «Таганрогского государственного радиоуниверситета», «Ростовского государственного педагогического университета» и «Ростовской государственной архитектурно-художественной академии». В его состав входят четыре ведущих специализированных университета на юге России, ЮФУ является научно-образовательным и научно-исследовательским центром юга России. История Южного федерального университета насчитывает более 100 лет. Его успехи отмечены в различных рейтингах: в России ЮФУ входит в топ-10 лучших университетов, а в мире - в топ-600 самых качественных учебных заведений.</p>
										</Card.Body>
									</Accordion.Collapse>
							</Card>
						</Accordion>
					</div>
					</div>
					<div className="container"><hr/></div>
{/*Конец новости */}
{/*Начало новости */}
				<div className="container">
					<h5 className="mt-3 mb-5 text-center">Рабочая встреча по программам российско-китайского
					академического сотрудничества</h5>
					<div className="d-flex justify-content-end">18.09.2020</div>
					<div className="row justify-content-center">
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-1.jpeg" alt="" />
						</div>
						<div className="col-12 col-md-6 mb-3">
							<img className="w-100" src="img/news/news-1.1.jpeg" alt="" />
						</div>
					</div>
					<div className="w-100 text-center">
						<Accordion defaultActiveKey="0">
							<Card border="light">
								<Card.Header>
									<div className="row justify-content-center">
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
									Узнать подробнее
									</Accordion.Toggle>
									</div>
								</Card.Header>
									<Accordion.Collapse eventKey="1">
									<Card.Body>
<p>Ректор РГЭУ (РИНХ) профессор, д.э.н. Елена Макаренко при участии начальника управления 
международного сотрудничества Олега Бодягина провела рабочую встречу с российскими представителями
Центра развития современной культуры провинции Хэнань, Китай, Чжан Цзинвэй и Андреем Каракуцем.
Участники встречи обсуждали возможности развития академического сотрудничества РГЭУ (РИНХ) 
с университетами провинции Хэнань. Ректор Елена Макаренко, приветствуя гостей, подчеркнула, 
что несмотря на временные ограничения международной мобильности, введенные в связи с необходимостью 
профилактики распространения новой короновирусной инфекции, международное академическое 
сотрудничество не прерывалось ни на минуту и должно вырабатывать новые формы и направления своего
развития. В качестве приоритетных направлений сотрудничества участники выделили: содействие 
изучению русского языка в Китае и китайского языка в России, развитие программ мобильности 
обучающихся всех уровней обучения, в том числе на основе разработки совместных образовательных
программ, организацию гостевых лекций преподавателей двух стран. Стороны согласовали дальнейшие
шаги по разработке программ сотрудничества.</p>

<p>РГЭУ (РИНХ) является признанным центром изучения китайского языка на Юге России, в университете
уже много лет ведется подготовка бакалавров по направлению «Зарубежное регионоведение», профиль 
«Восточная Азия» и магистров также по направлению «Зарубежное регионоведение», магистерская
программа «Этнические, социальные и лингвокультурологические особенности стран Восточной Азии»,
в рамках которых обучающиеся углубленно изучают китайский язык и литературу, историю и этнографию,
традиции и культурные ценности Китая. РГЭУ (РИНХ) ежегодно совместно с Институтом Конфуция
Калмыцкого государственного университета проводит сертификационные экзамены HSK/HSKK на знание
китайского языка.</p>
										</Card.Body>
									</Accordion.Collapse>
							</Card>
						</Accordion>
					</div>
					</div>
					<div className="container"><hr/></div>
{/*Конец новости */}
            

           </>
        );
    }
}

export default News;