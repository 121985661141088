import React, { Component } from "react";
import './universities.css';

class universities extends Component {
	render() {
		return (
			<div className="row">
				<div className="container">
					<h2 className="text-center mt-2">Вузы Китая</h2>
					<p>Платформа ЦЕАГС "ОДИН ПУТЬ" сотрудничает со многими вузами и организациями Китая. Некоторые из них представлены ниже.
                    </p>
					<ul>Вузы Китая:
                        <li>Хэнаньский университет финансовой экономики и права</li>
						<li>Хэнаньский университет легкой промышленности</li>
						<li>Гуйчжоуский университет</li>
						<li>Чжэнчжоуский университет</li>
						<li>Шанцюский педагогический университет</li>
						<li>Хэнаньский институт экономики и торговли</li>
						<li>Шанцюский технологический институт</li>
						<li>Шанцюский професссональный институт</li>
					</ul>
					<ul>Организации Китая:
                        <li>Центр развития современной культуры провинции хэнань</li>
						<li>Ассоциация по улучшению и развитию образования города шанцю</li>
					</ul>
				</div>
			</div>

		);
	}
}

export default universities;