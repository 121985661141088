import React, {Component} from "react";
import './tea-ceremony.css';

class teaCeremony extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Китайский чай</h2>
                <p>Для начала давайте разберемся, что же такое вообще этот загадочный китайский чай. 
                    Китайский чай – это растение Камелия Китайская (camellia sinensis), которое имеет форму куста, 
                    либо дерева. Только настой данного растения можно называть китайским чаем. Камелия Китайская 
                    растет как в диких условиях, так и на культивируемых плантациях, естественно, 
                    нас интересует именно культивируемая Камелия.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/teas_1.jpg" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>

                <p>Существует большое количество разновидностей и сортов китайского чая, этим и 
                    обусловлен широкий его ассортимент. В ход идет все: старые листья, молодые листья, 
                    побеги, а дальше начинается увлекательный и интересный процесс обработки.</p>
                <p>Сбор чайных листьев может осуществляться до 6 раз в год и ценность конечного 
                    продукта напрямую зависит от времени сбора. Так, чай летнего сбора считается наименее 
                    ценным, в то время как весенний сбор является наиболее дорогим и вкусным.</p>
                    <h3 className="text-center mt-3">Виды китайского чая</h3>
                <p>Китайский чай разделяется на 6 основных видов, которые, в свою очередь, имеют множество 
                    подвидов. Каждый вид и подвид обрабатывается своим особым способом, который и определяет 
                    органолептические характеристики конкретного сорта чая.</p>
                <ul>
                    <li>Зеленый чай;</li>
                    <li>Белый чай;</li>
                    <li>Желтый чай;</li>
                    <li>Улун;</li>
                    <li>Красный чай (тот самый любимый нами «черный» чай);</li>
                    <li>Черный чай</li>
                </ul>
                <h3 className="text-center mt-3">Ферментация</h3>
                <p>Самый важный процесс приготовления чая – это ферментация. Ферментацией называется процесс 
                    окисления чайного листа, при котором под действием микроорганизмов и ферментов происходит 
                    разрушение сложных нерастворимых химических веществ на более простые, растворимые и 
                    легкоусваемые. К примеру, хлорофилл, содержащийся в листьях, и придающий им зеленый цвет, 
                    в ходе ферментации распадается на дубильные вещества, придающие чаю особый вкус, крахмал, 
                    в свою очередь, превращается в сахар. Чай может быть полностью ферментирован, т.е. микроорганизмы 
                    переработают все питательные вещества в листе, либо ферментирован частично. В этом случае процесс 
                    ферментации прерывается искусственно – паром. Пар за счет своей температуры стерилизует чайный лист 
                    и уничтожает микроорганизмы, соответственно, процесс ферментации останавливается.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/teas_2.jpg" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
                <p>Соответственно, все виды чая можно условно разделить в том числе и по уровню ферментации. 
                    Наименее ферментированными являются зеленый и белый чаи (1-7%), желтый, в свою очередь, 
                    ферментирован на 5-15%, улуны на 15-80%, красный чай (наш повседневный напиток) имеет 
                    степень ферментации от 80 до 90%, а черный чай может быть ферментирован полностью – до 100%.</p>
                <h3 className="text-center mt-3">Зеленый чай (Люй Ча)</h3>
                <p>Данный чай является самым нежным и самым требовательным к себе в плане хранения и заваривания. 
                    Для его производства с чайного куста берут только самые молодые и нежные части – молодые почки 
                    и верхние недавно раскрывшиеся листья, причем сбор производится исключительно вручную, чтобы 
                    исключить возможность повреждения хрупких листьев. Заваривание данного чая так же процедура 
                    нетривиальная, достаточно лишь немного ошибиться с температурой и все, вкус напитка будет 
                    безнадежно испорчен. Самые известные представители данного вида – это Изумрудные Спирали 
                    Весны (Би Ло Чунь), Колодец Дракона (Лунцзин), Тыквенные Семечки из Люань (Люань Гуапянь), и т.д.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/teas_3.jpg" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
                <h3 className="text-center mt-3">Белый чай (Бай Ча)</h3>
                <p>Процесс производства данного чая схож с зеленым чаем, то же самое нежное сырье, те же молодые 
                    листья и почки, которые собирают исключительно вручную. Свое название чай получил из-за белого 
                    ворса на почках. Так же особо требователен к процессу заваривания. Если использовать слишком 
                    горячую воду, либо передержать чай, то вкус напитка будет испорчен. К нежным сортам чая следует 
                    подходить с нежностью при заваривании и употреблении. Наиболее известными сортами являются 
                    Серебрянные Иглы с Белым Ворсом (Бай Хао Инь Чжэнь), Белый Пион (Бай Му Дань), Брови Долголетия (Шоу Мэй), и т.д.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/teas_4.jpg" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
                <h3 className="text-center mt-3">Жёлтый чай (Хуан Ча)</h3>
                <p>Слабоферментированный чай с четко ограниченной географией производства, появившийся в провинции Хунань. 
                    Процесс приготовления схож с зеленым и белым чаями, однако данный чай проходит длительную стадию 
                    выдержки и термическую обработку, которая приводит к сахароаминной конденсации (реакция Майяра), 
                    придающей чаю приятный аромат. Наиболее известными чаями из данной группы являются Серебряные Иглы 
                    с горы Бессмертных (Цзюнь Шань Инь Чжэнь) и Желтые Почки с Горы Мэн Дин (Мэн Дин Хуан Я).</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/teas_5.jpg" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
                <h3 className="text-center mt-3">Улун (бирюзовый чай)</h3>
                <p>Очень популярный чай, который делится на две основные группы: светлый улун (слабоферментированный) 
                    и темный улун (сильноферментированный). Отличить их может даже не специалист. Светлый улун, к 
                    примеру, Те Гуаньинь, всегда скручен в плотный зеленый, с фиолетовыми акцентами, шарик, 
                    обладающий нежным цветочным ароматом. Темный же улун, в свою очередь, всегда свернут в трубочку, 
                    как знаменитый чай Да Хун Пао, обладающий более плотным телом, с нотками цветочного меда. Темный 
                    улун также могут подвергать термической обработке, что усиливает карамельно-медовые оттенки во вкусе и аромате.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/teas_6.jpg" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
                <h3 className="text-center mt-3">Красный чай (Хун Ча)</h3>
                <p>Это тот самый «черный чай», который мы так любим пить за завтраком, обедом и ужином, хотя, согласно 
                    правильной классификации, он считается красным. Данный чай проходит глубокую ферментацию после 
                    обязательной предварительной механической обработки чайного листа. Красный чай имеет широкую 
                    палитру вкусов, различающихся в зависимости от способа и места его приготовления.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/teas_7.jpg" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
                <h3 className="text-center mt-3">Черный чай (Хэй Ча)</h3>
                <p>В данную группу входят знаменитые пуэры (Шу и Шен). Чай имеет сильную ферментацию и часто долгое 
                    время выдерживается после ее завершения. На вкусоароматику влияет как срок выдержки, так и ее условия. 
                    Выдержка при повышенной влажности придает чаю «подвальный» аромат, характерный для некоторых сортов из 
                    Гуаньчжоу. Глубокая ферментация оказывает огромное влияние на вкус, органолеплический профиль данной 
                    группы чаев ближе к древесно-ореховому (Шу пуэр), либо к горьковато-травянистому (Шен пуэр).</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mb-3">
                        <img src="img/culture/teas_8.jpg" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>

            </div>

        );
    }
}

export default teaCeremony;