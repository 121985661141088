import React, {Component} from "react";
import './translate.css';

class translate extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Услуги по переводу</h2>
                <p>Центр Евроазиатского Гуманитарного Сотрудничества "Один Путь" предлагает предлагает полный комплекс услуг по переводу:</p>
                    <li>письменному;</li>
                    <li>устному.</li>
                    <p></p>
                <p>ЦЕАГС работает с русским, китайским и английским языком. Переводы с иностранных языков на русский и с 
                    русского на иностранные языки выполняются в основном силами наших штатных сотрудников, что обеспечивает 
                    оперативность и высокое качество работ.</p>
                <p>Стоимость устного перевода состоявляет от 1000₽ за час. Наша команда оказывает квалифицированную поддержку в ходе:</p>
                    <li>деловых переговоров;</li>
                    <li>деловые визитов;</li>
                    <li>краткосрочных зарубежных поездок;</li>
                    <li>международной деятельности;</li>
                    <li>долгосрочных командировок;</li>
                    <li>сопровождении выставок;</li>
                    <li>путешествий;</li>
                    <li>переводов по телефону;</li>
                    <li>зарубежных экскурсий.</li>
                    <p></p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/tran_1.png" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
                <p>Письменный перевод от 500 рублей за страницу:</p>
                    <li>официальных документов;</li>
                    <li>сертификатов;</li>
                    <li>дипломов;</li>
                    <li>тендерных документов;</li>
                    <li>финансовых отчетов;</li>
                    <li>устава компании;</li>
                    <li>договорных соглашений;</li>
                    <li>отчетов по проектам;</li>
                    <li>личных резюме;</li>
                    <li>профилей компаний;</li>
                    <li>корреспонденции.</li>

                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mb-3">
                        <img src="img/culture/tran_2.png" className="w-100 mt-2 mb-2" alt=""/>
                    </div>
                </div>
            </div>

        );
    }
}

export default translate;