import React, {Component} from "react";

class scienceAndTechnology extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Наука и технологии</h2>
                <p>Россия и КНР значительно повысили уровень научно-технического сотрудничества за последние несколько
                    лет. Одним из основных направлений нашей работы является организация научных контактов между учеными
                    и вузами из России и КНР, а также привлечение инвестиций со стороны КНР в научно-исследовательской
                    сфере. Правительства России и КНР уделяют большое внимание укреплению партнерских отношений в данной
                    сфере, 2020 и 2021 годы объявлены годами российско-китайского научно-технического и инновационного
                    сотрудничества, ключевыми направлениями взаимодействия определены здравоохранение и биотехнологии,
                    как наиболее актуальные на сегодняшний день, однако сотрудничество и по другим направлениям остается
                    актуальным.</p>
                    <p>"ЦЕНТР ЕВРОАЗИАТСКОГО ГУМАНИТАРНОГО СОТРУДНИЧЕСТВА "ОДИН ПУТЬ" не занимается сотрудничеством в сфере военных технологий и технологий двойного назначения.</p>
            </div>

        );
    }
}

export default scienceAndTechnology;