import React, {Component} from "react";


class culture extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Культура</h2>
                <p>Наша организация поможет вам окунуться в увлекательный мир китайской культуры, изучение которой
                    поможет вам не только расширить свой кругозор, но и более эффективно усваивать китайский язык,
                    понимая особенности китайского менталитета, на развитие которого напрямую влияет культурная среда.
                    Для вас мы предлагаем интересные лекции и презентации, проводимые нашими китайскими сотрудниками,
                    которые в простой и доступной форме расскажут вам о китайской культурной среде, нюансах культурной
                    жизни и взаимодействия в обществе. Вы откроете для себя интересный факт – китайская культура очень
                    близка к российской, особенно в области менталитета и ценностей.</p>
                <p>Чтобы понять Китай, следует для начала изучить его культуру, так как она является основой китайского
                    менталитета и всех успехов этого государства. Приоритет знания является основой китайской культуры и
                    это было заложено Конфуцием еще на рубеже 5-6 вв. до н.э. Все это дало китайцам работающую систему
                    социальных лифтов, благодаря которым к власти приходили самые способные и талантливые люди, так как,
                    прежде всего, приоритет отдавался именно их знаниями и навыкам.</p>
                <p>Единая культурная среда является подлинным достоянием китайской нации. Ценности всегда являются
                    общими для каждого члена общества, начиная от простого рабочего, заканчивая чиновниками и культурной
                    элитой. Общие ценности стали основной для знаменитого китайского коллективизма, который позволил
                    Китаю достичь колоссальных успехов во всем, начиная от строительства сооружений, по сей день
                    потрясающих своей масштабностью, таких, как Великая стена, или Великий канал, заканчивая созданием
                    крупнейшей экономики мира, опередившей США еще в 2016 году.</p>
                <p>Китайская цивилизация является одной из самых древних цивилизаций на нашей планете. В периоды, когда
                    Китай не тратил силы на борьбу с внешней агрессией, он всегда был на острие научной мысли.
                    Философия, химия, география, промышленность, во многих сферах китайскими учеными были совершены
                    знаковые открытия, бумага, порох, компас, книгопечатный станок – за все это мы должны благодарить
                    именно китайских ученых.</p>
                <p>В современно мире Китай успешно сохранил все свои культурные ценности, несмотря на
                    взаимопроникновение восточной и западной культурных парадигм, более того, многие из традиционных
                    китайских ценностей, таких как прагматизм и реалистичный взгляд на мир, стали неотъемлемой частью
                    мировоззрения многих людей.</p>
            </div>

        );
    }
}

export default culture;