import React, {Component} from "react";
import './online-platform.css';
import {Button} from "react-bootstrap";

class onlinePlatform extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Online платформа</h2>
                <p>Онлайн платформа для обучения китайскому языку была разработана специально для языкого Центра и
                    учитывает специфику изучения китайского языка именно русско-говорящими учениками. Для обучения в
                    режиме онлайн не существует территориальных ограничений.</p>
                <p>В любое время, в любом месте: Идеально составленный курс, позволит учиться когда и где угодно, с
                    любого девайса;</p>
                <p>Живые занятия: Квалифицированные преподаватели, поддерживающие вас на протяжении всего курса;</p>
                <p>В реальном времени, проверенный метод. Наш метод гарантирует быстрый прогресс в изучении китайского с
                    помощью онлайн-уроков и самостоятельной работы.</p>
                <ul>
                    <li>занятия проводят носители языка;</li>
                    <li>индивидуальное расписание занятий;</li>
                    <li>любой уровень подготовки;</li>
                    <li>бесплатные электронные учебники, аудиозаписи, учебные пособия;</li>
                    <li>для учебных занятий нужно иметь только стационарный компьютер или ноутбук;</li>
                    <li>Один цикл (8 занятий) стоит 5000 рублей, два занятия в неделю, одно занятие идёт час (60
                        минут).
                    </li>
                </ul>
                <strong>ОТЛИЧИЯ ОНЛАЙН КУРСА ОТ ЗАНЯТИЙ (ПО SKYPE ，ЗООМ , ТЕАМS ...):</strong>
                <ul>
                    <li>можно слушать аудиозаписи и китайские песни;</li>
                    <li>возможность в любое время смотреть видео на китайском языке (например, видеоматериалы про
                        культуру, историю, традиции и обычаи Китая);
                    </li>
                    <li>материалы для подготовки к занятиям и повторения пройденных уроков;</li>
                    <li>уникальная доска для написания иероглифов.</li>
                </ul>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/3/8.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                <div className="w-100 text-center mb-5 mt-3">
                    <Button href="/forms" variant="primary">Записаться на курсы</Button>
                </div>
            </div>

        );
    }
}

export default onlinePlatform;