import React, {Component} from "react";
import './Chinese-cuisine.css';

class ChineseCuisine extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Китайская кухня</h2>
                <p>Китайская кухня - это общий термин, обозначающий блюда из разных регионов Китая. 
                    Она имеет долгую истории, множество технологий приготовления отдельных блюд,
                    множество жанров и уникальных стилей. У неё высокая репутация и спрос в мире.</p>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                        <img src="img/culture/cous_2.jpg" className="w-100" alt=""/>
                    </div>
                    </div>
                <p>Существуют так называемые восемь видов китайской кухни:</p>
                    <li>сладкая кантонская, известная своими нежными и хрустящими блюдами;</li>
                    <li>аньхойская;</li>
                    <li>ароматная, сладкая и свежая фуцзяньская;</li>
                    <li>масляная и разноцветная хунаньская;</li>
                    <li>кухня цзянсу;</li>
                    <li>необычная шаньдунская — именно там готовят суп из акульих плавников и ласточкины гнёзда;</li>
                    <li>острая и жирная сычуаньская известна сохранением исходных вкусов блюд и виртуозной работой ножом;</li>
                    <li>чжэцзянская.</li>
               

                <div className="row justify-content-center">
                <div className="col-12 col-md-6 mb-3">
                        <img src="img/culture/cous_3.png" className="w-100" alt=""/>
                    </div>
                </div>

            </div>

        );
    }
}

export default ChineseCuisine;