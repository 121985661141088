import React, {Component} from "react";
import './Chinese-language-courses-children.css';
import {Button} from "react-bootstrap";

class ChineseLanguageCoursesChildren extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Курсы китайского языка для детей</h2>
                <p>Занятия по китайскому языку для детей в возрасте до 7 лет ориентированы на переход от игровой
                    деятельности к учебной, но развлекательная роль остается ведущей.</p>
                <p>Занятия по китайскому языку для детей в возрасте до 7 лет ориентированы на переход от игровой
                    деятельности к учебной, но развлекательная роль остается ведущей.</p>
                <ul>
                    <li>Занятия проводят носители языка, владеющие русским языком;</li>
                    <li>Уровни подготовки: начальный, базовый, продвинутый, профессиональный;</li>
                    <li>Программа рассчитана на 4 года обучения;</li>
                    <li>Количество учащихся в группе 2-6 человек;</li>
                    <li>Занятия проводятся и в будние, и в выходные дни;</li>
                    <li>Один цикл (8 занятий) стоит 5000 рублей, два занятия в неделю, одно занятие идёт час (60
                        минут).
                    </li>
                    <li>Можно прийти и бесплатно посмотреть как проходит занятие уже сформировавшейся группы.</li>
                </ul>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-4 mb-3">
                        <img src="img/3/6.jpg" className="w-100" alt=""/>
                    </div>
                    <div className="col-12 col-md-5">
                        <img src="img/3/7.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                <div className="w-100 text-center mb-5 mt-5">
                    <Button href="/forms" className="btn-success" variant="primary">Записаться на курсы</Button>
                </div>

            </div>

        );
    }
}

export default ChineseLanguageCoursesChildren;