import React, {Component} from "react";
import './calligraphy.css';

class calligraphy extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Каллиграфия</h2>
                <p>Испокон веков известно, что каллиграфия не является письменным китайским. Это отдельный вид живописи, 
                    искусство красивого и изящного письма. Искусством каллиграфии обладают не все жители КНР.</p>

                    <div className="row justify-content-center">
                    <div className="col-12 col-md-5 mb-4"><img src="img/culture/call_1.png" className="w-100" alt=""/></div>
                </div>

                <p>Постичь искусство китайской каллиграфии можно на особых курсах, где признанные учителя с точностью показывают 
                    и демонстрируют в каком порядке и как нужно изображать фрагменты иероглифа, какую силу нажатия прикладывать на 
                    инструмент и какую бумагу использовать. Залог настоящего искусства в качественных материалах и инструментах. 
                    Нельзя просто взять и изобразить настоящее произведение искусства на обычной бумаге, используя простую краску 
                    и кисточку. Настоящий мастер будет использовать только самую качественную бумагу - рисовую или бамбуковую, а 
                    кисть должна быть выполнена из натуральных компонентов. Так же, особое внимание уделяется ёмкости под туш - 
                    она должна быть каменная.</p>

                       <div className="row justify-content-center">
                    <div className="col-12 col-md-5 mb-4"><img src="img/culture/call_2.png" className="w-100" alt=""/></div>
                </div>

                <p>Процесс нанесения иероглифа подчиняется выверенным правилам. Например, вначале должны быть изображены 
                    вертикальные линии, далее горизонтальные, а после диагональные. Правила меняются от школы и стиля каллиграфии. 
                    Последних в Китае известно всего 5 основных стилей:</p>

                    <li>Чжуаньшу;</li>
                    <li>Лишу;</li>
                    <li>Синшу;</li>
                    <li>Цаошу;</li>
                    <li>Кайшу.</li>
                
                <p></p>
                <p>Каждый стиль имеет свою особенность нанесения, историю создания и выдающихся мастеров.Стоит отметить, что некоторые 
                    стили, такие как Чжуаньшу или Цаошу, без специальной подготовки прочитать будет очень затруднительно. А например, 
                    стиль Синшу доступен для чтения любому образованному китайцу.</p>

                <p>В настоящее время, искусство каллиграфии служит не столько как источник информации и знаний, а больше для выражения 
                    на холсте мыслей и чувств автора иероглифов. Каллиграфия это величайшее искусство, которое впитало в себя мудрость 
                    тысячелетий, философию древнего Китая и неповторимую культуру Поднебесной. Это поистине гордость китайского народа.</p>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-5 mb-4"><img src="img/culture/call_3.png" className="w-100" alt=""/></div>
                </div>

            </div>

        );
    }
}

export default calligraphy;