import React, { Component } from "react";
import { Button, Carousel } from 'react-bootstrap';
import { Map, Placemark, YMaps } from 'react-yandex-maps';
import Modals from "../Modals";
import News from "../News";
import './Home.css';

const mapData = {
	center: [47.219754, 39.710755],
	zoom: 15,
};

const coordinates = [
	[47.219754, 39.710755],

];

class Home extends Component {

	render() {
		
		return (

			<>
				{/*<ModalsHello />*/}
				<Carousel>

					<Carousel.Item>
						<img
							className="d-block w-100"
							src="img/slaider/1.jpg"
							alt="First slide"
						/>

						<Carousel.Caption>
							<h1 className="mt-0">Курсы китайского языка</h1>
							<h4>Идёт набор новых групп. 4-х недельный курс стоит всего 5 000 рублей</h4>
							<Button href="/forms" className="button-carousel btn-outline-light"
								variant="outline-primary">Записаться на курсы</Button>
						</Carousel.Caption>

					</Carousel.Item>
					<Carousel.Item>
						<img
							className="d-block w-100"
							src="img/slaider/4.jpg"
							alt="Second slide"
						/>
						<Carousel.Caption className="mt-0">
							<h1>Обучение в Китае</h1>
							<h4>ЦЕАГС "ОДИН ПУТЬ" поможет Вам поступить по гранту в ведущие вузы Китая</h4>
							<Modals />
						</Carousel.Caption>

					</Carousel.Item>
					<Carousel.Item>
						<img
							className="d-block w-100"
							src="img/slaider/3.jpg"
							alt="Third slide"
						/>
						<Carousel.Caption className="pt-0">
							<h1>Сотрудничество в сферах Образования, Культуры и Науки</h1>
							<h4>ЦЕАГС поможет вашему вузу привлечь студентов из КНР для обучения на коммерческой основе</h4>
							<Modals />
						</Carousel.Caption>

					</Carousel.Item>


				</Carousel>

					<h5 className="text-center mt-3 mb-0">Основные направления CEAGS.RU</h5>
					<div className="container">
					<div className="container"><hr/></div>
					<p>"Центр Евроазиатского гуманитарного сотрудничества "Один Путь" создан для расширения
					сотрудничества в области образования сфере между КНР и Россией.</p>

					<p>Наша цель – установление контактов и связей между школами КНР и России, колледжами и высшими
                        учебными заведениями этих стран, организация новых партнерств «школа-вуз», «вуз-вуз» и т.п.</p>
				</div>
                {/* Новости начало*/}
				<News />
				{/* Новости конец*/}
				<h4 className="text-center mb-0"> Схема проезда </h4>
				<div className="container mt-4 mb-5">
					<div className="row index-card-block">
						<div className="index-card col-md-9 col-xl-10">
							<YMaps>
								<Map width='100%'
									height='200px' defaultState={mapData}>
									{coordinates.map(coordinate => <Placemark geometry={coordinate} />)}
								</Map>
							</YMaps>
						</div>
						<div className="col-md-3 col-xl-2">
							<div className="indexSocialLinks__items">
								<h5>Мы в соцсетях</h5>
								<a className="indexSocialLinks__item" href="https://vk.com/ceags"
									target=" _blank">
									<svg viewBox="0 0 24 24" id="vkontakte">
										<path fill="#4680C2" fill-rule="evenodd" clip-rule="evenodd"
											d="M6 0h12a6 6 0 016 6v12a6 6 0 01-6 6H6a6 6 0 01-6-6V6a6 6 0 016-6zm13.122 7.622c.525 0 .644.27.525.643-.185.854-1.721 3.119-2.197 3.82-.09.134-.143.211-.14.211-.186.305-.254.44 0 .78.092.125.286.316.509.534.229.225.487.478.693.719.745.847 1.321 1.558 1.474 2.05.135.49-.102.744-.61.744h-1.744c-.463 0-.701-.265-1.209-.83-.218-.243-.485-.541-.84-.897-1.034-1-1.491-1.135-1.745-1.135-.356 0-.458.085-.458.593v1.575c0 .424-.135.678-1.253.678-1.846 0-3.896-1.118-5.335-3.202C4.624 10.857 4.03 8.553 4.03 8.096c0-.254.085-.491.593-.491h1.761c.44 0 .61.186.78.677.863 2.49 2.303 4.675 2.895 4.675.22 0 .322-.102.322-.66V9.721c-.041-.732-.296-1.05-.485-1.287-.117-.147-.209-.262-.209-.424 0-.203.17-.406.44-.406h2.744c.373 0 .508.203.508.643v3.473c0 .372.153.508.271.508.22 0 .407-.136.813-.542 1.254-1.406 2.151-3.574 2.151-3.574.119-.254.322-.491.762-.491h1.745z"></path>
									</svg>
									<span>ВКонтакте</span></a>
								<a className="indexSocialLinks__item"
									href="https://t.me/center_china_russia" target=" _blank">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"/></svg>
									<span>Telegram</span></a>
								<a className="indexSocialLinks__item"
									href="https://www.facebook.com/ceags.ru/" target=" _blank">
									<svg viewBox="0 0 24 24" id="facebook">
										<path fill="#3B5998" fill-rule="evenodd" clip-rule="evenodd"
											d="M6 0h12a6 6 0 016 6v12a6 6 0 01-6 6H6a6 6 0 01-6-6V6a6 6 0 016-6zm4.551 19v-7H9V9.586h1.551V8.139c0-1.968.875-3.139 3.364-3.139h2.07v2H14c-.5 0-1 .37-1 1v1.587h3l-.274 2.412L13 12v7h-2.449z"></path>
									</svg>
									<span>Facebook</span></a>

								<a className="indexSocialLinks__item"
									href="https://www.instagram.com/ceags.ru/" target=" _blank">
									<svg className="instagram" fill="#000000" height="24" viewBox="0 0 24 24"
										width="24"
										xmlns="http://www.w3.org/2000/svg">
										<path fill="red"
											d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
									</svg>
									<span>Instagram</span></a>
							</div>
						</div>
					</div>
				</div>

			</>
		)
			;
	}
}

export default Home;