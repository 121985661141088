import React, {Component} from "react";

class eventsScience extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Организация мероприятий в сфере науки</h2>
                <p>Центр Евроазиатского Гуманитарного Сотрудничества "Один Путь" предлагает организацию научных 
                    мероприятий как на территории Российской Федерации, так и на территории Китайской Народной 
                    Республики. Формат мероприятий может быть самым разнообразным, начиная от конференций по 
                    видеосвязи, заканчивая международными визитами межвузовских делегаций и встречами с высокопоставленными 
                    представителями научного сообщества.</p>
            </div>

        );
    }
}

export default eventsScience;