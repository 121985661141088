import React, {Component} from "react";
import './Conversation-club.css';
import {Button} from "react-bootstrap";

class ConversationClub extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Разговорный клуб</h2>
                <p>Разговорный клуб – это тематические занятия на китайском языке, где в неформальной обстановке, в ходе
                    дискуссии или игры учащиеся узнают новые слова и речевые обороты, развивают и поддерживают
                    разговорные навыки.</p>
                <p>Разговорный клуб даёт прекрасную возможность преодолеть языковой барьер, применить свои знания на
                    практике, познакомиться с интересными людьми и просто весело провести время!</p>
                <p>В разговорном клубе нет учебников и домашнего задания, там не пишут диктанты, и не ставят оценки!</p>
                <ul>Особенности посещения разговорного клуба:
                    <li>Занятия проводят только носители языка;</li>
                    <li>Каждый раз разные и интересные темы, ролевые игры;</li>
                    <li>В рамках разговорного клуба проводятся мастер-классы, праздники, викторины;</li>
                    <li>Количество учащихся в группе 2-6 человек;</li>
                    <li>Можно прийти и бесплатно посмотреть как проходит занятие уже сформировавшейся группы.</li>
                    <li>Один цикл (8 занятий) стоит 5000 рублей! Одно занятие идёт час (60 минут), два занятия в неделю.
                    </li>
                </ul>
                <p>В настоящее время огромную популярность набирает китайский язык. Изучение этого мелодичного и
                    красивого языка открывает для всех желающих множество возможностей и перспектив. Языковой центр
                    предлагает Вашему вниманию китайский разговорный клуб, где Вы сможете открыть для себя удивительный
                    мир востока, его культуру и традиции. Мы используем только эффективные программы и современные
                    методики обучения. Наши опытные преподаватели помогут Вам в быстрые сроки овладеть одним из самых
                    красивых и древних языков мира.</p>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-5"><img src="img/3/3.jpg" className="w-100" alt=""/></div>
                </div>

                <div className="w-100 text-center mb-3 mt-3">
                    <Button href="/forms" className="btn-success" variant="primary">Записаться на курсы</Button>
                </div>
            </div>

        );
    }
}

export default ConversationClub;