import React, {Component} from "react";

class education extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Образование</h2>
                <p>ЦЕАГС «Один Путь» осуществляет широкий спектр задач в сфере образовательной деятельности в России и
                    КНР.</p>
                <ul>Нашими основными задачами являются:
                    <li>координация работы образовательных организаций, заинтересованных в обменах и продвижении своих
                        программ; организация и реализация совместных межвузовских программ бакалавриата, магистратуры,
                        и аспирантуры;</li>
                    <li>обеспечение и поддержка академической мобильности студентов;</li>
                    <li>организация совместно с вузами региона летних школ русского языка и культуры для китайских
                        школьников, планирующих поступление в российские вузы;
                    </li>
                    <li>привлечение выпускников китайской старшей школы для обучения на подготовительных отделениях в
                        вузы России;
                    </li>
                    <li>привлечение российских выпускников к обучению в китайских вузах;</li>
                    <li>организация и проведение международных научных конференций и других образовательных
                        мероприятий;
                    </li>
                    <li>заключение договоров о международном сотрудничестве в сфере образования;</li>
                    <li>организация языковых курсов, в том числе в дистанционном формате;</li>
                </ul>
                <p>На данный момент наша организация заключила ряд трехсторонних договоров о сотрудничестве между
                    российскими и китайскими вузами.</p>
            </div>

        );
    }
}

export default education;