import React, { Component } from "react";
import { Button, Modal, Nav } from 'react-bootstrap';
import './Footer.css';


class Footer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			show: false

		}
	}

	onClick() {
		this.setState({ show: !this.state.show })
	}

		
	
	render() {
		return (
			<footer>
				<Modal show={this.state.show}>
					<Modal.Header>
						<h6 className="ml-3">Оставить заявку или узнать подробности Вы можете по телефону: +7 (989)
                            629-31-56 или заполнив форму ниже:</h6>
					</Modal.Header>
					<Modal.Body>
						<form action="email_telegram.php" method="POST">
							<div className="form-group">
								<label className="d-flex justify-content-center" htmlFor="">Введите ваше имя</label>
								<input type="text" className="form-control" id="" name="user_name"
									placeholder="Например, Иван" required />
							</div>

							<div className="form-group">
								<label className="d-flex justify-content-center" htmlFor="">Введите номер
                                    телефона</label>
								<input type="text" className="form-control" id="" name="user_phone"
									placeholder="+7 (999) 999 99 99" required />
							</div>

							<div className="form-group">
								<label className="d-flex justify-content-center" htmlFor="">Введите email</label>
								<input type="text" className="form-control" id="" name="user_email"
									placeholder="name@mail.ru" required />
							</div>
							<label className="d-flex justify-content-center" htmlFor="">Дополнительная
                                информация</label>
							<div className="w-100"><textarea rows="3" cols="63" class="form-control" name="text">
							</textarea></div>
							<button type="submit" className="btn btn-primary mt-2 w-100">Отправить</button>
						</form>
					</Modal.Body>
					<Modal.Footer>
						<Button className="btn-danger" onClick={() => {
							this.onClick()
						}}>Закрыть</Button>
					</Modal.Footer>
				</Modal>

				<div className="container">
					<div className="row mob">
						<div className="col-md-4 col-10 bottom-text">
							<h1>ЦЕАГС "ОДИН ПУТЬ"</h1>
							<p>344002, г. Ростов-на-Дону,<br />
                                пер. Соборный д. 21, офис 4б<br />
                                График работы:<br />
                                пн-вс 9:00-21:00<br />
                                без выходных</p>
						</div>
						<div className="col-md-4 col-10 bottom-bar">
							<div className="footer-menu">
								<h1>ОБУЧЕНИЕ КИТАЙСКОМУ ЯЗЫКУ</h1>
								<Nav.Link href="/aboutUs">О нас</Nav.Link>
								<Nav.Link href="/Group-Chinese-Courses">Групповые курсы китайского языка</Nav.Link>
								<Nav.Link href="/OneToOneChineseLessons">Индивидуальные занятия китайского
                                    языка</Nav.Link>
								<Nav.Link href="/PreparationHSK">Подготовка к HSK</Nav.Link>
							</div>
						</div>
						<div className="col-md-4 col-10 bottom-logo">
							<Nav.Link href="/forms">Записаться на курсы</Nav.Link>
							<Nav.Link onClick={() => {
								this.onClick()
							}}>Обратная связь
                            </Nav.Link>
							<Nav.Link href="!#">
								<svg className="lexicon-icon lexicon-icon-zh-cn" viewBox="0 0 512 512">
									<rect y="64" fill="#C0CFD8" width="512" height="384"></rect>
									<rect x="16" y="80" fill="#E03232" width="480" height="352"></rect>
									<polygon fill="#FCD638" points="152.7,238.7 112.7,209.3 73.6,240 88,191.1 48,161.7 96.9,160.9 111.3,112 127.1,160.4 176,159.6
	136.9,190.3 "></polygon>
									<polygon fill="#FFDD00" points="236.6,154.2 226.6,140.1 210.3,145.9 220.5,131.4 210.5,117.3 226.7,122.4 236.9,107.9 236.7,125.6
	252.9,130.7 236.7,136.5 "></polygon>
									<polygon fill="#FFDD00" points="275.6,203.8 276.2,186.5 259.7,181.4 276.5,175.9 277,158.6 286.9,172.4 303.7,166.9 293,181
	302.9,194.8 286.4,189.7 "></polygon>
									<polygon fill="#FFDD00" points="267,276.9 272.2,260.4 257.7,251 275.4,250.2 280.5,233.8 286.3,249.7 304,249 289.9,259.6
	295.6,275.6 281.1,266.2 "></polygon>
									<polygon fill="#FFDD00" points="207.9,319.2 219.8,306.8 211.1,291.9 227.2,299.1 239.2,286.7 237.2,303.6 253.4,310.8 236,314
	234.1,330.9 225.3,316 "></polygon>
								</svg>
                                China version</Nav.Link>


						</div>
					</div>
				</div>
				<div className="site-info">Copyright © 2021 ЦЕАГС "ОДИН ПУТЬ"</div>
			</footer>

		);
	}
}

export default Footer;