import React, {Component} from "react";
import './China-city-tour.css';

class ChinaCityTour extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Экскурсия по Китаю</h2>
                <p>Китай богат для туризма. Огромное количество прекрасных мест можно разделить по ландшафтным особенностям:</p>
                    <li>Места близи озер (Байяндянь, Западное озеро Ханчжоу, озеро Нанкин Сюаньу, Восточное озеро Ухань, 
                        Синьцзян Тяньшань Тяньчи, озеро Цинхай, водохранилище Даньцзянкоу);</li>
                        <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_1.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                    <li>Горные районы (Яньшань, Тайшань, Хэншань, Хуашань, Пурпурная гора, Алишань);</li>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_2.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                    <li>Лесные районы (Сишуанбаньна, Хунань Чжанцзяцзе, Хэнань Баотяньман, Сычуань Вулонг, Шеннунцзя, Хубэй);</li>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_3.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                    <li>Живописный ландшафт (Гуйлинь Лицзян, Три ущелья реки Янцзы, ручей Уи Цзюцюй);</li>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_4.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                    <li>Прибрежный район (Хайнань Тянья Хайцзяо, Сямэнь, Далянь).</li>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_5.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                    <p>В Китае большое множество красивых мест (более 35000), более 350 участков туризма и 25 курортов 
                        национального уровня. Именно там находятся 9 городов-центров туризма, 109 национальных зон экологического 
                        туризма и другие места отдыха.</p>
                    <p>Многие туристы, побывавшие в Китае, рекомендуют следующие места в качестве обязательных к посещению:</p>
                    <li>Пекин - не только столица Китая, но и так называемая "древняя столица шести династий"</li>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_6.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                    <li>Шанхай - китайский волшебный город или восточная жемчужина. Именно здесь смешались восточная и западная культуры</li>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_7.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                    <li>Сучжоу - один из старейших городов Китая, место рождения культуры Цзяннань. Очень крупный и известный 
                        туристический город Китая.</li>
                        <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_8.jfif" className="w-100" alt=""/>
                    </div>
                </div>
                    <li>Ханчжоу - одна из шести древних столиц Китая, столица провинции Чжэцзян, политический, экономический и культурный 
                        центр провинции Чжэцзян, а также важный транспортный, информационный, культурный и экономический центр Восточного Китая.</li>
                        <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_9.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                    <li>Сиань - это китайский древний город. В Китае принято нахывать "древней столицей восьми династий"
                        Это национальный региональный центр Китая, одна из четырех всемирно известных четырех древних цивилизаций в мире и 
                        первая из четырех древних столиц Китая. Это самая влиятельная столица в истории Китая с самым большим количеством династий.</li>
                        <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_10.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                    <li>Гуанчжоу -  город Гуанчжоу расположен в южно-центральной части провинции Гуандун, является столицей. Один из крупнейших городов 
                        Китая национального уровня. Город представляет собой мегаполис. Это крупнейший политический, 
                        экономический и культурный центр Южного Китая.</li>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 mt-3 mb-3">
                        <img src="img/culture/tour_11.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                
            </div>

        );
    }
}

export default ChinaCityTour;