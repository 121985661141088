import React, {Component} from "react";
import './Preparation-HSK.css';
import {Button} from "react-bootstrap";

class PreparationHSK extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Подготовка к HSK</h2>
                <p>Специализированный учебный курс ориентирован на учащихся, которые будут сдавать экзамены по
                    китайскому языку HSK.</p>
                <p>HSK - это экзамен по китайскому языку (путунхуа) для лиц, не являющихся носителями китайского языка,
                    а именно иностранных студентов, зарубежных китайцев и представителей этнических меньшинств,
                    проживающих в КНР. Квалификационный экзамен HSK является единой формой аттестации иностранных
                    учащихся. Результаты экзамена HSK признаются во всем мире. После окончания курса слушатели могут
                    сдать экзамен в нашем Центре в установленные сроки и в соответствии с международными
                    требованиями.</p>
                <p>
                    После окончания курса слушатели могут сдать экзамен в нашем Центре в установленные сроки и в
                    соответствии с международными требованиями.
                </p>
                <ul>
                    <li>Занятия проводят носители языка, владеющие русским языком;</li>
                    <li>Один цикл курса рассчитан на 8 занятий по 6000 рублей, одно занятие по 60 минут, два занятия в
                        неделю;
                    </li>
                    <li>Количество учащихся в группе 2-6 человек;</li>
                    <li>Занятия проводятся и в будние, и в выходные дни;</li>
                    <li>Можно прийти и бесплатно посмотреть как проходит занятие уже сформировавшейся группы.</li>
                </ul>
                <div className="row justify-content-center">
                    <div className="col-4 col-md-3">
                        <img src="img/3/4.jpg" className="w-100" alt=""/>
                    </div>
                    <div className="col-6 col-md-4 mb-3">
                        <img src="img/3/5.jpg" className="w-100" alt=""/>
                    </div>
                </div>

                <div className="w-100 text-center mb-5 mt-3">
                    <Button href="/forms" variant="primary">Записаться на курсы</Button>
                </div>
            </div>

        );
    }
}

export default PreparationHSK;