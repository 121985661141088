import React, {Component} from "react";
import { Nav, Navbar, NavDropdown} from 'react-bootstrap';
import './Header.css';

class Header extends Component {

   

    render() {
        
        return (
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="logo-block">
                            <a href="/">
                                <img className="logo" src="logo.png" alt="logo"/>
                            </a>
                        </div>
                        <div className="header-text">
                            <p className="g-center">Центр Евроазиатского Гуманитарного Сотрудничества "Один
                                Путь"</p>
                            <p className="g-center-1">俄 罗 斯 欧 亚 人 文 合 作 中 心 “ 一 带 一 路 ”</p>
                            <p className="g-center">Две великие страны. Два великих народа. Одно
                                великое будущее. Один путь!</p>
                        </div>
                    </div>
                </div>
                <Navbar className="nav-block" expand="lg">
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="m-auto">
                            <Nav.Link href="aboutUs">О НАС</Nav.Link>
                            <NavDropdown title="Образование в Китае" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/education-system">Система образования</NavDropdown.Item>
                                <NavDropdown.Item href="/language-camp">Языковой лагерь</NavDropdown.Item>
                                <NavDropdown.Item href="/universities">Вузы</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Центр китайского языка"
                                         id="basic-nav-dropdown">
                                <NavDropdown.Item href="/Group-Chinese-Courses">Групповые курсы китайского
                                    языка</NavDropdown.Item>
                                <NavDropdown.Item href="/OneToOneChineseLessons">Индивидуальные занятия китайского
                                    языка</NavDropdown.Item>
                                <NavDropdown.Item href="/Conversation-Club">Разговорный клуб</NavDropdown.Item>
                                <NavDropdown.Item href="/PreparationHSK">Подготовка к HSK</NavDropdown.Item>
                                <NavDropdown.Item href="/ChineseLanguageCoursesChildren">Курсы китайского языка для
                                    детей</NavDropdown.Item>
                                <NavDropdown.Item href="/onlinePlatform">Online платформа</NavDropdown.Item>
                                <NavDropdown.Item href="/translate">Услуги по переводу</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Сферы сотрудничества" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/education">Образование</NavDropdown.Item>
                                <NavDropdown.Item href="/culture">Культура</NavDropdown.Item>
                                <NavDropdown.Item href="/scienceAndTechnology">Наука и технологии</NavDropdown.Item>
                                <NavDropdown.Item href="/eventsScience">Организация мероприятий в сфере науки</NavDropdown.Item>
                                <NavDropdown.Item href="/universityCooperation">Межвузовское сотрудничество</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Культура" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/teaCeremony">Чайная церемония</NavDropdown.Item>
                                <NavDropdown.Item href="/calligraphy">Каллиграфия</NavDropdown.Item>
                                <NavDropdown.Item href="/ChineseHolidays">Китайские традиционные праздники</NavDropdown.Item>
                                <NavDropdown.Item href="/ChinesePainting">Китайская живопись</NavDropdown.Item>
                                <NavDropdown.Item href="/ChineseCuisine">Китайская кухня</NavDropdown.Item>
                                <NavDropdown.Item href="/ChinaCityTour">Экскурсия по Китаю</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="contact">КОНТАКТЫ</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </header>
        );
    }
}

export default Header;