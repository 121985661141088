import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import ScrollUpButton from "react-scroll-up-button";

import Header from "./res/Header";
import Home from "./res/Home";
import aboutUs from "./res/Header/aboutUs";
import education_system from "./res/Header/education_in_china/education_system";
import language_camp from "./res/Header/education_in_china/language_camp";
import universities from "./res/Header/education_in_china/universities";
import GroupChineseCourses from "./res/Header/Chinese-language-center/Group-Chinese-courses";
import OneToOneChineseLessons from "./res/Header/Chinese-language-center/One-to-one-Chinese-lessons";
import ConversationClub from "./res/Header/Chinese-language-center/Conversation-club";
import PreparationHSK from "./res/Header/Chinese-language-center/Preparation-HSK";
import ChineseLanguageCoursesChildren from "./res/Header/Chinese-language-center/Chinese-language-courses-children";
import onlinePlatform from "./res/Header/Chinese-language-center/online-platform";
import translate from "./res/Header/Chinese-language-center/translate";
import education from "./res/Header/areas-cooperation/education";
import culture from "./res/Header/culture";
import scienceAndTechnology from "./res/Header/areas-cooperation/science-and-technology";
import eventsScience from "./res/Header/areas-cooperation/events-science";
import universityCooperation from "./res/Header/areas-cooperation/university-cooperation";

import teaCeremony from "./res/Header/culture/tea-ceremony";
import calligraphy from "./res/Header/culture/calligraphy";
import ChineseHolidays from "./res/Header/culture/Chinese-holidays";
import ChinesePainting from "./res/Header/culture/Chinese-painting";
import ChineseCuisine from "./res/Header/culture/Chinese-cuisine";
import ChinaCityTour from "./res/Header/culture/China-city-tour";

import contact from "./res/Header/contact";
import forms from "./res/form";
import Footer from "./res/Footer";

var destination = document.querySelector("#page");

ReactDOM.render(
 <div className="container-fluid">
  <Header/>
  <BrowserRouter>
   <Switch>
    <Route exact path="/" component={Home}/>
    <Route path="/aboutUS" component={aboutUs}/>


    <Route path="/education-system" component={education_system}/>
    <Route path="/language-camp" component={language_camp}/>
    <Route path="/universities" component={universities}/>


    <Route path="/Group-Chinese-Courses" component={GroupChineseCourses}/>
    <Route path="/OneToOneChineseLessons" component={OneToOneChineseLessons}/>
    <Route path="/Conversation-Club" component={ConversationClub}/>
    <Route path="/PreparationHSK" component={PreparationHSK}/>
    <Route path="/ChineseLanguageCoursesChildren" component={ChineseLanguageCoursesChildren}/>
    <Route path="/onlinePlatform" component={onlinePlatform}/>
    <Route path="/translate" component={translate}/>


    <Route path="/education" component={education}/>
    <Route path="/culture" component={culture}/>
    <Route path="/scienceAndTechnology" component={scienceAndTechnology}/>
    <Route path="/eventsScience" component={eventsScience}/>
    <Route path="/universityCooperation" component={universityCooperation}/>

    <Route path="/teaCeremony" component={teaCeremony}/>
    <Route path="/calligraphy" component={calligraphy}/>
    <Route path="/ChineseHolidays" component={ChineseHolidays}/>
    <Route path="/ChinesePainting" component={ChinesePainting}/>
    <Route path="/ChineseCuisine" component={ChineseCuisine}/>
    <Route path="/ChinaCityTour" component={ChinaCityTour}/>

    <Route path="/contact" component={contact}/>


    <Route path="/forms" component={forms}></Route>

   </Switch>
  </BrowserRouter>
  <Footer/>
  <ScrollUpButton />
 </div>,
    destination
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
