import React, {Component} from "react";

class language_camp extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Языковой лагерь</h2>
                <p>Языковой лагерь в провинции Хэнань – это отличная возможность погрузиться в атмосферу Китая, а также
                    ощутить на себе силу китайского языка и менталитета. Провинция Хэнань является колыбелью китайской
                    нации. На протяжении тысяч лет это был политический, культурный, экономический и транспортный центр
                    страны.</p>
                <p>Скажите 你好 ([nǐhǎo] - здравствуйте) новым впечатлениям, откройте для себя Китай. Ваши знания за
                    короткий период времени получат колоссальный прирост. Вы повысите свой текущий уровень, путешествуя
                    и исследуя современную инфраструктуру Китая. Языковые курсы в провинции Хэнань представлены
                    различными направлениями и могут быть подстроены под интересы и задачи студентов. Это отличная
                    практика для достижения поставленных задач.</p>
                <p>ЦЕАГС “Один Путь” открывает для Вас программы языкового лагеря, рассчитанные на различные возрастные
                    группы и временные затраты.</p>
                <ul>Программа языкового лагеря включает:
                    <li>углубленное изучение китайского языка и культуры;</li>
                    <li>погружение в атмосферу боевых искусств Китая;</li>
                    <li>туристические экскурсии по достопримечательностям;</li>
                    <li>занятия спортом;</li>
                </ul>
                <ul>Посещение летнего лагеря в Китае даст Вам возможность:
                    <li>улучшить свой уровень китайского языка;</li>
                    <li>получить новые навыки письма на занятиях по китайской каллиграфии;</li>
                    <li>узнать множество интересных фактов по истории Китая;</li>
                    <li>получить личностный рост на фоне полученных впечатлений.</li>
                </ul>
                <ul>Отдельные программы языкового лагеря включают такие направления как:
                    <li>“Шаолинь”</li>
                </ul>
                <p>Очень мало существуют в мире монастырей, которые могут потягаться известностью с китайским храмом
                    “Шаолинь”. Особое почитание и славу монастырю принесла не только 1500-летняя история, но и
                    устоявшаяся философия дзен-буддизма, а также порожденный ею особый стиль кунг-фу. Сегодня, “Шаолинь”
                    это не просто храм, это мировой бренд получивший популярность и известность практически в любой
                    точке мира.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-4 mb-5">
                        <img src="img/2/language/1.jpg" className="w-100" alt=""/>
                    </div>
                    <div className="col-12 col-md-4 mb-5">
                        <img src="img/2/language/2.jpg" className="w-100" alt=""/>
                    </div>
                </div>
                <h3 className="text-center mt-3">Лунмыль</h3>
                <p>«Лунмынь» (гроты «Ворота дракона») - один из трех сокровищниц каменного скульптурного искусства
                    Китая. Входит в составленный ЮНЕСКО перечень всемирного наследия. Создан в 495—898 годах в
                    известняковых скалах по берегам реки Ихэ. Храмовый комплекс, вырезанный в гранитной скале, поражает
                    воображение. Это уникальное строение в своём роде. Именно здесь находятся тысячи статуй Будды
                    различных размеров. Почти у каждого - свой личный грот. Посещение сооружения такого рода как Лунмынь
                    оставляет неизгладимые впечатления.</p>
                <div className="row">
                    <div className="col-12 col-md-4 mb-5"> <img src="img/2/language/3.jpg" className="w-100" alt=""/></div>
                    <div className="col-12 col-md-4 mb-5"><img src="img/2/language/4.jpg" className="w-100" alt=""/></div>
                    <div className="col-12 col-md-4 mb-5"><img src="img/2/language/5.jpg" className="w-100" alt=""/></div>
                </div>



                <h3 className="text-center mt-3">Баймасы</h3>
                <p>Баймасы (храм белой лошади)— первый буддийский храм Китая. Был основан в 68 году императором Мин-ди в
                    городе Лоян. На сегодняшний день это мировой памятник архитектуры и объект паломничества. По
                    легенде, однажды император увидел во сне огромное божество, прибывшее с запада и источающее свет.
                    Решив, по совету сановников, что речь идет о Будде, император снарядил посольство в Индию, которое
                    вернулось в Лоян со статуей Гаутамы и «Сутрой из 42 глав». В честь этого события и был воздвигнут
                    храм. А свое имя он получил благодаря белой лошади, на которой святыни прибыли в Лоян. Ее память
                    увековечили в скульптуре. Храм белой лошади по праву считается колыбелью буддизма в Китае, ведь
                    именно он стал отправной точкой новой веры, привезенной из Индии.</p>
                <div className="row mb-5">
                    <div className="col-12 col-md-4 mb-5"><img src="img/2/language/6.jpg" className="w-100" alt=""/></div>
                    <div className="col-12 col-md-4 mb-5"><img src="img/2/language/7.jpg" className="w-100" alt=""/></div>
                    <div className="col-12 col-md-4 mb-5"><img src="img/2/language/8.jpg" className="w-100" alt=""/></div>
                </div>



            </div>

        );
    }
}

export default language_camp;