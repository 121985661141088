import React, {Component} from "react";

class aboutUs extends Component {
    render() {
        return (
            <div className="container">
                    <h2 className="text-center mt-2">О нас</h2>

                        <p>"ЦЕНТР ЕВРОАЗИАТСКОГО ГУМАНИТАРНОГО СОТРУДНИЧЕСТВА "ОДИН ПУТЬ" создан для расширения
                            сотрудничества в области образования сфере между КНР и Россией.
                        </p>
                        <p>Наша цель – установление контактов и связей между школами КНР и России, колледжами и высшими
                            учебными заведениями этих стран, организация новых партнерств «школа-вуз», «вуз-вуз» и
                            т.п.
                        </p>
                        <ul><strong>Нашими основными задачами являются:</strong>
                        </ul>
                        <li> координация работы образовательных организаций, заинтересованных в обменах и продвижении
                            своих программ; организация и реализация совместных межвузовских программ бакалавриата по
                            схеме «2+2», магистратуры - по схеме «1+1», аспирантуры - по схеме «1+1+1».
                        </li>
                        <li>обеспечение и поддержка академической мобильности студентов.</li>
                        <li>организация совместно с вузами региона летних школ русского языка и культуры для китайских
                            школьников, планирующих поступление в российские вузы.
                        </li>
                        <li>привлечение выпускников китайской старшей школы для обучения на подготовительных отделениях
                            в вузы России.
                        </li>
                        <li>привлечение российских выпускников к обучению в китайских вузах.</li>
                        <li>организация языковых курсов, в том числе в дистанционном формате.</li>

                        <p>Мы привлекаем дополнительные иностранные инвестиции в российские высшие учебные заведения за
                            счет иностранных студентов, обучающихся на коммерческой основе, а также позволяем улучшить
                            позиции российских вузов в мировых рейтингах за счет граждан КНР, получивших образование в
                            России, и продолжающих заниматься научной деятельностью после окончания обучения.</p>

            </div>
        );
    }
}

export default aboutUs;