import React, {Component} from "react";
import './education_system.css';

class education_system extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Система образования</h2>
                <p>Основы современной китайской образовательной системы были заложены в первые годы правления
                    коммунистической партии. Благодаря этой системе, за несколько десятилетий властям удалось
                    полностью
                    искоренить безграмотность (до 1949 года читать и писать умели всего 20% китайцев) и ввести
                    обязательное начальное и среднее образование. Несмотря на то, что многие западные педагоги часто
                    критикуют китайские образовательные принципы, сами жители Поднебесной считают эту систему весьма
                    эффективной и отлаженной.
                </p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                        <img src="/img/2/education/1.png" className="w-100" alt=""/>
                    </div>
                </div>
                <h2 className="text-center">Система дошкольного образования в Китае </h2>

                <p>С 3 до 6 лет дети посещают детские сады. Вся образовательная система Китая строится, в первую
                    очередь, на дисциплине. С самых ранних лет дети должны строго следовать расписанию и указаниям
                    взрослых. Воспитатели контролируют даже детские игры. По мнению китайских педагогов, подобная
                    строгость позволяет ребёнку стать полноценным членом общества, найти своё место в жизни и
                    приносить
                    максимальную пользу своему народу.
                </p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-4">
                        <img src="/img/2/education/2.png" className="w-100" alt=""/>
                    </div>
                </div>
                <h2 className="text-center">Школьное образование</h2>
                <strong>В Китае школьное обучение продолжается 12 лет и делится на три этапа:</strong>
                <ul>
                    <li>Начальный (6 лет). При поступлении в школу каждый ребёнок обязан сдать несколько тестов.
                        На
                        данном этапе дети получают базовые знания о мире и обществе. Тогда же они впервые пробуют
                        себя в
                        качестве работников. Школьное образование предполагает прохождение детьми производственной
                        практики на предприятиях или фермах.
                    </li>
                    <li>Средний (3 года). На данной ступени дети проходят углублённые программы по точным наукам,
                        знакомятся с информатикой, изучают иностранные языки, узнают больше о политике и
                        государственном
                        устройстве родной страны. После окончания обязательного девятилетнего обучения школьник
                        может не
                        переходить в старшие классы, а получить среднее профессиональное образование в техникуме или
                        училище.
                    </li>
                    <li>Старший (3 года). В отличие от первых двух этапов, обучение в старших классах платное. На
                        эту
                        ступень обучения переходят только те дети, которые желают поступить в ВУЗ. Перед началом
                        учебного года школьнику предстоит выбрать профильное направление —
                        профессионально-техническое
                        или академическое — и сдать соответствующие тесты.
                    </li>
                </ul>
                <p>В Китае очень ценится образование и карьера. Даже младшеклассники осознают всю важность учёбы и
                    стараются заниматься как можно прилежнее. Начиная с первого класса, дети выполняют большие
                    домашние работы и дополнительно занимаются с репетиторами. Также от учеников требуется строгое
                    соблюдение школьной дисциплины. Достаточно пропустить всего 12 занятий без уважительной причины,
                    чтобы оказаться отчисленным из школы.</p>

                <p>Как правило, учебный день каждого китайского школьника состоит из 6-7 (у старшеклассников — из
                    8-9)
                    уроков и посещения многочисленных дополнительных занятий, факультативов и спортивных секций.
                    Уроки
                    длятся по 40 минут. Каждый день проводятся уроки физкультуры. Поскольку занятия продолжаются
                    очень
                    долго, после обеда наступает время своеобразного «тихого часа», который длится 60-80 минут.
                    Обычно
                    до перерыва дети изучают наиболее сложные предметы, а во второй половине дня — более лёгкие и
                    творческие. В течение года школьники и студенты уходят на каникулы дважды:</p>
                <ul>
                    <li>Летние каникулы продолжаются с начала июля по сентябрь;</li>
                    <li>Новогодние каникулы начинаются в середине января и заканчиваются в середине февраля.</li>
                </ul>
                <p>Во время каникул дети продолжают учиться. К началу нового семестра им нужно будет представить
                    учителям готовые домашние задания. Кроме того, многих школьников на время каникул родители
                    отправляют заграницу, чтобы подтянуть иностранный язык или на дополнительные образовательные
                    курсы.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-4">
                        <img src="/img/2/education/3.png" className="w-100" alt=""/>
                    </div>
                </div>
                <h3 className="text-center">Система высшего образования в Китае</h3>
                <p>ВУЗы Китая считаются одними из лучших в Азии. Дипломы, выданные многими из них, высоко ценятся в
                    Европе и Америке. Китайское руководство делает очень многое для развития национальной высшей
                    школы.
                    Сегодня большая часть китайских ВУЗов — это огромные высокотехнологичные научные комплексы с
                    библиотеками, музеями и современными лабораториями. Для чтения лекций в университеты часто
                    приглашают лучших профессоров со всего мира.</p>
                <p>Все ВУЗы Китая разделены на несколько категорий, в зависимости от престижа и качества обучения.
                    Выпускники школ, готовящиеся к поступлению в университет, сдают единый экзамен, результаты
                    которого
                    оцениваются по стобалльной шкале. Для того чтобы быть допущенным к сдаче вступительных экзаменов
                    в
                    ВУЗ, относящийся к определённой категории, выпускник должен сдать единый экзамен на
                    соответствующее
                    число баллов. Обычно поступление в ВУЗы проходит в условиях жёсткой конкуренции. В отдельных
                    китайских университетах конкурс достигает нескольких сотен человек на место.</p>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-4">
                        <img src="/img/2/education/4.png" className="w-100" alt=""/>
                    </div>
                </div>
                <h3 className="text-center">Аспирантура</h3>

                <strong>В Китае, как и на западе, существует трёхуровневая система высшего образования:</strong>
                <ul>
                    <li>Бакалавриат (4 года);</li>
                    <li>Магистратура (2-3 года). На этом этапе предполагается углублённое изучение некоторых
                        предметов.
                    </li>
                    <li>Докторантура (2-4 года).</li>
                </ul>

                <p>Аспирантом может стать студент, получивший диплом бакалавра. При поступлении в магистратуру
                    студент
                    должен очень внимательно подходить к выбору направления обучения, поскольку тема будущей
                    диссертации
                    должна будет соответствовать выбранной области знаний.</p>
                <p>Обучение в магистратуре предполагает посещение семинаров и лекций, подготовку собственных
                    проектов и
                    докладов, а также публикацию статей в научных сборниках. За каждую выполненную работу учащийся
                    получает баллы, по которым оценивается его работа в течение года. Если аспирант набирает
                    необходимое
                    количество баллов, он получает право на написание собственной диссертации. Помощь в подготовке
                    диссертации аспиранту оказывает куратор, однако, вмешательство научного руководителя в работу
                    подопечного, как правило, сводится к минимуму.</p>
                <p>Основное требование к готовой диссертации — это её уникальность. Работы, содержащие более 15%
                    плагиата, к защите не допускаются.</p>
                <p>Как правило, обучение в аспирантуре платное, но студенты с актуальными и важными темами работ,
                    могут
                    рассчитывать на получение правительственного гранта.</p>
                <p>Сегодня образовательные учреждения Китая ежегодно привлекают десятки тысяч студентов со всего
                    земного
                    шара. Диплом китайского университета для многих стал показателем качества и престижа.</p>
                <ul>
                    <li>Летний лагерь;</li>
                    <li>Вузы;</li>
                </ul>
                <div className="row justify-content-center mb-5">
                    <div className="col-12 col-md-4">
                        <img src="/img/2/education/5.png" className="w-100" alt=""/>
                    </div>
                </div>
            </div>

        );
    }
}

export default education_system;