import React, {Component} from "react";
import './One-to-one-Chinese-lessons.css';
import {Button} from "react-bootstrap";

class OneToOneChineseLessons extends Component {
    render() {
        return (
            <div className="container">
                <h2 className="text-center mt-2">Индивидуальные занятия</h2>
                <p>Один из самых эффективных способов изучения иностранного языка, в частности, китайского —
                    индивидуальные занятия с репетитором. Особенно, если репетитор – носитель языка! Преимущества
                    индивидуальных занятий:</p>
                <ul>
                    <li>Особый подход во всём;</li>
                    <li>Занятия проводят носители языка, владеющие русским языком;</li>
                    <li>Индивидуальный план занятий, соответсвующий конкретным целям и пожеланиям учащегося;
                    </li>
                    <li>Индивидуальный план занятий, соответствующий конкретным целям и пожеланиям учащегося;
                    </li>
                    <li>Вы можете заниматься с преподавателем в нашем учебном классе, или педагог приедет к Вам домой
                        или в офис;
                    </li>
                    <li>Программа может использоваться для обучения детей в возрасте от 5-х лет и старше;</li>
                    <li>Один цикл (8 занятий) стоит 10000 рублей! одно занятие идёт час (60 минут), два занятия в
                        неделю.
                    </li>
                </ul>

                <div className="row justify-content-center">
                    <div className="col-12 col-md-6"><img src="img/3/2.jpg" className="w-100" alt=""/></div>
                </div>
                <div className="w-100 text-center mb-5 mt-3">
                    <Button href="/forms" variant="primary">Записаться на курсы</Button>
                </div>
            </div>

        );
    }
}

export default OneToOneChineseLessons;